import PropTypes from 'prop-types';
import Layout from 'components/Layout';

export default function Custom404({ route }) {
    return (
        <Layout route={route} section="contact" seo={{ title: 'Contact' }}>
            <section className="section has-background-white py-6">
                <div className="container is-size-5 has-text-centered">
                    <div className="columns">
                        <div className="column is-8 is-offset-2">
                            <h1 className="title is-unselectable has-text-primary mb-6">404</h1>
                            <hr />
                            <p className="has-text-grey-dark mt-6">Oops... You hit the wall!</p>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
}

Custom404.propTypes = {
    route: PropTypes.string,
};
